/* You can add global styles to this file, and also import other style files */
@use 'anchor';
@use 'buttons';
@use 'select';
@use 'snackbar';
@use 'common';
@use 'inputs';
@use 'flag';
@use 'map';
@use './../../../libs/animation/src/lib/p-skeleton/skeleton.component';
@import 'cookieconsent';
@import 'custom_bootstrap';
@import 'custom_icon_font';
@import 'swiper/swiper-bundle.min.css';

:root {
  --swiper-theme-color: var(--bs-pink);
  --swiper-navigation-color: var(--bs-white);
}

html {
  /* sticky elem miatt a body nem lehet 100% height */
  height: 100%;
}

html,
body {
  min-height: 100%;
}

svg {
  vertical-align: unset !important;
}

///* Importing Datepicker SCSS file. */
//@import "~ngx-bootstrap/datepicker/bs-datepicker";

.top-bar-select-panel.mat-select-panel {
  top: 45px !important;
  padding: 10px !important;
  position: absolute !important;

  .mat-option {
    height: 30px;
    color: #454F63 !important;
    border-radius: 5px !important;

    &:hover {
      color: #FFF !important;
      background-color: #00B267 !important;
    }

    &.mat-active {
      color: #454F63 !important;
      border-radius: 5px !important;
      background-color: #F8F8F8 !important;
    }
  }
}
