.flag-container {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: flex-start;
}

.flag {
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: var(--bs-body-bg);
  margin-left: 3px;

  &--primary {
    background-color: var(--bs-pink);

    &:before {
      border-color: var(--bs-pink) transparent transparent transparent;
    }
    &:after {
      border-color: transparent var(--bs-pink) transparent transparent;
    }
  }

  &--secondary {
    background-color: rgba(var(--bs-black-rgb), 0.5);

    &:before {
      border-color: rgba(var(--bs-black-rgb), 0.5) transparent transparent
        transparent;
    }
    &:after {
      border-color: transparent rgba(var(--bs-black-rgb), 0.5) transparent
        transparent;
    }
  }

  &--badge {
    padding: 8px 6px;
    font-size: 14px;
    font-weight: 600;
    width: 32px;
    text-align: center;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 16px 0 0;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 16px 10px 0;
    }
  }
}
