@import 'colors';

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here

// Default variable overrides
$body-bg: #fff;
$body-color: #212529;
$font-family-base: Montserrat, Arial;

$spinner-width: 40px;

// input variables
/* ez kihatassal van minden gomb focus eseten az outline box-shadow-ra */
$input-btn-focus-width: 0.08rem;
// 3. Include remainder of required Bootstrap stylesheets
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// navbar variables
$navbar-light-color: rgba($white, 0.85);
$navbar-light-hover-color: rgba($white, 0.5);
$navbar-light-active-color: rgba($white, 0.7);
$navbar-light-disabled-color: rgba($white, 0.4);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($white, 0.3);
//$navbar-toggler-focus-width
// other
$theme-colors: (
  'primary': $primary,
  'pink': $pink,
  'dark-grey': $dark-grey,
  'green': $green,
  'dark-green': $dark-green,
  'light': $light,
  'light-2': $light-2,
  'light-3': $light-3,
);

$font-sizes: map-merge(
  $font-sizes,
  (
    7: $font-size-base * 0.875,
    8: $font-size-base * 0.75,
  )
);

// disable smooth scroll
$enable-smooth-scroll: false;

@import '~bootstrap/scss/utilities';
$utilities: map-merge(
  $utilities,
  (
    'opacity': null,
    'overflow': null,
    'display':
      map-merge(
        map-get($utilities, 'display'),
        (
          print: false,
        )
      ),
    'shadow': null,
    'border-top':
      map-merge(
        map-get($utilities, 'border-top'),
        (
          responsive: true,
        )
      ),
    'border-end': null,
    'border-start': null,
    'border-width': null,
    'max-width': null,
    'viewport-width': null,
    'min-viewport-width': null,
    'max-height': null,
    'viewport-height':
      map-merge(
        map-get($utilities, 'viewport-height'),
        (
          responsive: false,
        )
      ),
    'min-viewport-height': null,
    'margin':
      map-merge(
        map-get($utilities, 'margin'),
        (
          responsive: false,
        )
      ),
    'margin-x':
      map-merge(
        map-get($utilities, 'margin-x'),
        (
          responsive: false,
        )
      ),
    'margin-y':
      map-merge(
        map-get($utilities, 'margin-y'),
        (
          responsive: false,
        )
      ),
    'margin-top':
      map-merge(
        map-get($utilities, 'margin-top'),
        (
          responsive: true,
        )
      ),
    'margin-end':
      map-merge(
        map-get($utilities, 'margin-end'),
        (
          responsive: false,
        )
      ),
    'margin-bottom':
      map-merge(
        map-get($utilities, 'margin-bottom'),
        (
          responsive: true,
        )
      ),
    'margin-start':
      map-merge(
        map-get($utilities, 'margin-start'),
        (
          responsive: true,
        )
      ),
    'negative-margin':
      map-merge(
        map-get($utilities, 'negative-margin'),
        (
          responsive: false,
        )
      ),
    'negative-margin-x':
      map-merge(
        map-get($utilities, 'negative-margin-x'),
        (
          responsive: false,
        )
      ),
    'negative-margin-y':
      map-merge(
        map-get($utilities, 'negative-margin-y'),
        (
          responsive: false,
        )
      ),
    'negative-margin-top':
      map-merge(
        map-get($utilities, 'negative-margin-top'),
        (
          responsive: false,
        )
      ),
    'negative-margin-end':
      map-merge(
        map-get($utilities, 'negative-margin-end'),
        (
          responsive: false,
        )
      ),
    'negative-margin-bottom':
      map-merge(
        map-get($utilities, 'negative-margin-bottom'),
        (
          responsive: false,
        )
      ),
    'negative-margin-start':
      map-merge(
        map-get($utilities, 'negative-margin-start'),
        (
          responsive: false,
        )
      ),
    'padding':
      map-merge(
        map-get($utilities, 'padding'),
        (
          responsive: true,
        )
      ),
    'padding-x':
      map-merge(
        map-get($utilities, 'padding-x'),
        (
          responsive: false,
        )
      ),
    'padding-y':
      map-merge(
        map-get($utilities, 'padding-y'),
        (
          responsive: false,
        )
      ),
    'padding-top':
      map-merge(
        map-get($utilities, 'padding-top'),
        (
          responsive: true,
        )
      ),
    'padding-end':
      map-merge(
        map-get($utilities, 'padding-end'),
        (
          responsive: true,
        )
      ),
    'padding-bottom':
      map-merge(
        map-get($utilities, 'padding-bottom'),
        (
          responsive: false,
        )
      ),
    'padding-start':
      map-merge(
        map-get($utilities, 'padding-start'),
        (
          responsive: true,
        )
      ),
    'font-family': null,
    'font-style': null,
    'line-height':
      map-merge(
        map-get($utilities, 'line-height'),
        (
          responsive: false,
        )
      ),
    'text-align':
      map-merge(
        map-get($utilities, 'text-align'),
        (
          responsive: true,
        )
      ),
    'text-decoration': null,
    'white-space': null,
    'world-wrap': null,
    'color':
      map-merge(
        map-get($utilities, 'color'),
        (
          values: $theme-colors,
        )
      ),
    'text-opacity': null,
    'bg-opacity': null,
    'gradient': null,
    'rounded-top': null,
    'rounded-end': null,
    'rounded-bottom': null,
    'rounded-start': null,
  )
);
