@font-face {
  font-family: 'myfont';
  src: url('/assets/icon/custom-font/myfont.eot');
  src: url('/assets/icon/custom-font/myfont.eot?#iefix') format('eot'),
    url('/assets/icon/custom-font/myfont.woff2') format('woff2'),
    url('/assets/icon/custom-font/myfont.woff') format('woff'),
    url('/assets/icon/custom-font/myfont.ttf') format('truetype'),
    url('/assets/icon/custom-font/myfont.svg#myfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  font-family: 'myfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon:before {
  display: inline-block;
  font-family: 'myfont';
  font-style: normal;
  font-weight: normal;
  vertical-align: top;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: '\EA01';
}
.icon-arrowDown:before {
  content: '\EA02';
}
.icon-arrowLeft:before {
  content: '\EA03';
}
.icon-arrowRight:before {
  content: '\EA04';
}
.icon-arrowSelect:before {
  content: '\EA05';
}
.icon-arrowUp:before {
  content: '\EA06';
}
.icon-banner:before {
  content: '\EA07';
}
.icon-calendar:before {
  content: '\EA08';
}
.icon-camera:before {
  content: '\EA09';
}
.icon-cancel:before {
  content: '\EA0A';
}
.icon-cart:before {
  content: '\EA0B';
}
.icon-check:before {
  content: '\EA0C';
}
.icon-close:before {
  content: '\EA0D';
}
.icon-close2:before {
  content: '\EA0E';
}
.icon-close3:before {
  content: '\EA0F';
}
.icon-delete:before {
  content: '\EA10';
}
.icon-eye:before {
  content: '\EA11';
}
.icon-facebook-share:before {
  content: '\EA12';
}
.icon-facebook:before {
  content: '\EA13';
}
.icon-globe:before {
  content: '\EA14';
}
.icon-gps:before {
  content: '\EA15';
}
.icon-group:before {
  content: '\EA16';
}
.icon-instagram:before {
  content: '\EA17';
}
.icon-linkedin:before {
  content: '\EA18';
}
.icon-mail:before {
  content: '\EA19';
}
.icon-minus:before {
  content: '\EA1A';
}
.icon-next:before {
  content: '\EA1B';
}
.icon-pin:before {
  content: '\EA1C';
}
.icon-play:before {
  content: '\EA1D';
}
.icon-previous:before {
  content: '\EA1E';
}
.icon-profile:before {
  content: '\EA1F';
}
.icon-radar:before {
  content: '\EA20';
}
.icon-rss:before {
  content: '\EA21';
}
.icon-search:before {
  content: '\EA22';
}
.icon-twitter:before {
  content: '\EA23';
}
.icon-warning:before {
  content: '\EA24';
}
