@import 'colors';

.cd-input,
.cd-textarea {
  width: 100%;
  height: 36px;
  padding: 0 10px;
  border: 1px solid $light-3;
  border-radius: 4px;
  font-size: 12px;
  color: $primary;
}

.cd-textarea {
  height: 120px;
  padding: 10px;
  resize: none;
}

.cd-checkbox {
  font-family: var(--bs-body-font-family);

  .mat-checkbox-inner-container {
    width: 18px;
    height: 18px;
  }

  .mat-checkbox-frame {
    border: 1px solid var(--bs-light);
  }

  .mat-checkbox-layout {
    align-items: flex-start;
  }

  .mat-checkbox-inner-container {
    margin: 2px 8px 0 0;
  }

  .mat-checkbox-label {
    color: var(--bs-dark-grey);
    font-size: 12px;
    white-space: pre-wrap;

    a {
      text-decoration: none;
      color: #007bff;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: #0056b3;
      }
    }
  }

  .mat-checkbox-checkmark-path {
    stroke: var(--bs-green) !important;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background: transparent !important;
    }
  }
}

.cd-radio {
  &.mat-radio-button {
    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: var(--bs-green);
      }
    }

    .mat-radio-inner-circle {
      background: var(--bs-green);
    }
  }
}

.cd-label {
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 5px;
  color: var(--bs-dark-grey);
}

.cd-label__required {
  color: var(--bs-red);
}

.cd-legend {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
  color: var(--bs-dark-grey);
}

input:disabled {
  background-color: #e0e0e0 !important;
}
