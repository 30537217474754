.skeleton-loader {
  background: #eff1f6 no-repeat;
  //border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: calc(100% - 1rem);
  will-change: transform;
}

.skeleton-loader:after,
.skeleton-loader:before {
  box-sizing: border-box;
}

.skeleton-loader.circle {
  border-radius: 50%;
  height: 40px;
  margin: 5px;
  width: 40px;
}

.skeleton-loader.progress,
.skeleton-loader.progress-dark {
  transform: translateZ(0);
}

.skeleton-loader.progress-dark:after,
.skeleton-loader.progress-dark:before,
.skeleton-loader.progress:after,
.skeleton-loader.progress:before {
  box-sizing: border-box;
}

.skeleton-loader.progress-dark:before,
.skeleton-loader.progress:before {
  -webkit-animation: progress 2s ease-in-out infinite;
  animation: progress 2s ease-in-out infinite;
  background-size: 200px 100%;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 200px;
  z-index: 1;
}

.skeleton-loader.progress:before {
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.6),
    hsla(0, 0%, 100%, 0)
  );
}

.skeleton-loader.progress-dark:before {
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.2),
    transparent
  );
}

.skeleton-loader.pulse {
  -webkit-animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  -webkit-animation-delay: 0.5s;
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

@media (prefers-reduced-motion: reduce) {
  .skeleton-loader.progress,
  .skeleton-loader.progress-dark,
  .skeleton-loader.pulse {
    -webkit-animation: none;
    animation: none;
  }
  .skeleton-loader.progress,
  .skeleton-loader.progress-dark {
    background-image: none;
  }
}
@-webkit-keyframes progress {
  0% {
    transform: translate3d(-200px, 0, 0);
  }
  to {
    transform: translate3d(calc(200px + 100vw), 0, 0);
  }
}
@keyframes progress {
  0% {
    transform: translate3d(-200px, 0, 0);
  }
  to {
    transform: translate3d(calc(200px + 100vw), 0, 0);
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
