@import 'colors';

.cd-btn {
  min-width: 170px;
  padding: 12px 25px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  text-decoration: none !important;
  display: block;
  transition: background-color 1s ease-out, border-color 1s ease-out;
  //color: $greey;

  &:hover {
    color: white;
  }

  &--sm-width {
    min-width: 120px !important;
  }

  &--sm {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &--green {
    background-color: $green;
    color: white !important;

    &:hover {
      background-color: $dark-green;
    }

    &.cd-btn--stroked {
      color: $green !important;
      border-color: $green;

      &:hover {
        color: $dark-green !important;
        border-color: $dark-green !important;
      }
    }
  }

  &--pink {
    background-color: $pink;
    color: white !important;

    &:hover {
      background-color: darken($pink, 20%);
    }

    &.cd-btn--stroked {
      color: $pink !important;
      border-color: $pink;

      &:hover {
        color: darken($pink, 20%) !important;
        border-color: darken($pink, 20%) !important;
      }
    }
  }

  &--rounded {
    border-radius: 20px;
    border: 1px solid $greey;
    color: $greey;
  }

  &--stroked {
    color: black;
    border: 3px solid black;
    background-color: transparent !important;
    transition: color 1s ease-out, border-color 1s ease-out;
  }

  &--no-style {
    border: 0;
    background: transparent;
    padding: 0.25rem;
    margin: 0;
    color: rgba(var(--bs-black-rgb), 0.5);
    transition: color 1s ease-out;
    text-decoration: none;

    &:hover {
      color: var(--bs-pink);
    }
  }

  &--hover-pink {
    &:hover {
      background-color: var(--bs-pink);
    }
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}
