$primary: #454f63;
$pink: #ff1866;
$dark-grey: #959cac;
$green: #00b267;
$dark-green: #218437;
$light: #cfd5de;
$light-2: #e3e3e3;
$light-3: #d2d7db;
$font-color-01: #454f63;
$greey: #959cac;
