@import 'colors';
/**
ez egy regi maradvagny :(  majd refactoralni kell
 */
.snackBarStyleForSave {
  //kosaras
  position: absolute;
  z-index: 999999 !important;
  bottom: 1rem;
  line-height: 20px;
  text-align: center;
  right: 1rem;
  // background: linear-gradient(-90deg, #74be03, #28a745);
  background: #00b267;
  color: white;
  font-style: oblique;
  min-height: 20px;
  min-width: 0 !important;
  /* min-width: 240px!important; */
  max-width: 300px !important;
  padding: 15px;
  cursor: pointer;
}

.snackBarStyleForError {
  position: absolute;
  z-index: 99999;
  bottom: 1rem;
  line-height: 20px;
  text-align: center;
  right: 1rem;
  background: $pink;
  /*
  color: antiquewhite;
  */
  color: white;
  font-style: oblique;
  min-height: 20px;
  min-width: 0 !important;
  max-width: 300px !important;
  padding: 15px;
  cursor: pointer;

  .mat-button-wrapper {
    color: white !important;
    font-weight: bold;
  }
}
