.cd-select {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;

  .mat-form-field-wrapper {
    .mat-form-field-infix {
      padding-top: 0.6em !important;
    }
  }

  .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
  }
}

.mat-select-panel .mat-option {
  white-space: normal;
  line-height: 20px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  height: auto !important;
  min-height: 3em;

  .mat-option-text {
    text-overflow: unset;
  }
}
