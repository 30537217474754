.center- {
  &vertical {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
  }

  &horizontal {
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
  }

  &vertical-and-horizontal {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

hr {
  margin: 45px 0 !important;
  background-color: var(--bs-green) !important;
  height: 2px !important;
  opacity: 1 !important;
}
