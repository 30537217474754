@import 'colors';

.cd-link {
  text-decoration: none !important;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $green;
  }

  &.active {
    font-weight: bold;
    color: $green;

    &:hover {
      color: black;
    }
  }
}
