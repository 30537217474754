heremap {
  .H_ib_body {
    box-shadow: 6px 7px 8px -5px rgb(var(--bs-black-rgb));
    border-radius: 0.2em;
    background: var(--bs-body-bg);
    padding: 0;
  }

  .H_ib_content {
    padding: 0;
    margin: 0;
  }

  .H_ib_tail {
    display: none;
  }
}
